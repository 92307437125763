<template>
  <slot />
</template>
<script lang="ts" setup>
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import { watch } from "vue";
import { useToken } from "~/composables/useToken";
import { ROUTES_NAME } from "~/config/router";
import { useAuthStore } from "~/stores/authStore";

const { getData } = useVisitorData({ extendedResult: true });

const data = ref<any>({}); // Initialize data as an empty object
const token = useToken();
const route = useRoute();

// Toggle visibility of the Telegram back button
const toggleBackButtonVisibility = () => {
  if (window.history.length > 1 && !route.path.includes(ROUTES_NAME.LOBBY)) {
    window.Telegram.WebApp.BackButton.show();
  } else {
    window.history.replaceState(null, "", route.fullPath);
    window.Telegram.WebApp.BackButton.hide();
  }
};

// Handle Telegram Login Logic
const handleTelegramLogin = async (user_data: any) => {
  const referenceIdCookie = useCustomCookie("reference_id");
  const affiliateTokenCookie = useCustomCookie("affiliate_token");

  const reference = referenceIdCookie.value || null;
  const affiliate_token = affiliateTokenCookie.value || null;

  const visitor_id = data.value?.visitorId || null;

  const dataRequest = {
    user_data,
    reference,
    affiliate_token,
    visitor_id,
  };

  const authStore = useAuthStore();
  await authStore.telegramLogin(dataRequest);
};

onMounted(async () => {
  // Only getDate one time to make sure run both on telegram and normal web
  data.value = await getData({ ignoreCache: true });
  if (window.Telegram?.WebApp || route.query.tgWebAppData) {
    // Extract user data from the Telegram WebApp API or query parameters
    const user_data =
      window.Telegram.WebApp.initData || route.query.tgWebAppData;

    if (user_data && !token.value) {
      await handleTelegramLogin(user_data);
    }

    // Back button handling and visibility
    toggleBackButtonVisibility();
    window.Telegram.WebApp.enableClosingConfirmation();

    // Handle back button click
    window.Telegram.WebApp.BackButton.onClick(() => {
      if (window.history.length > 1) {
        window.history.back(); // Navigate to the previous page
      }
    });

    // Watch route changes to toggle back button visibility
    watch(() => route.fullPath, toggleBackButtonVisibility);
  }
});
</script>
